import FqApi from '@/services/fq-api'

const baseUrl = '/emails'

export default {
  async send(formData) {
    const result = await FqApi.post('/emails/send', formData)
    return result
  },
  async sendExport(quoteId, exportOptions, reportOptions, formData) {
    const url = `/emails/sendtext/${quoteId}?exportOptions=${exportOptions}&reportOptions=${reportOptions}`
    const result = await FqApi.post(url, formData)
    return result
  },
  async getAssetsEmailAddresses(filter) {
    const url = 'emails/assetsemails'
    const result = await FqApi.post(url, filter)
    return result.data
  },
  async getEntitySummaries(filter) {
    const result = await FqApi.post(`${baseUrl}/template/list`, filter)
    return result.data
  },
  async postEntity(entity) {
    const result = await FqApi.post(`${baseUrl}/new`, entity)
    return result.data
  },
  async putEntity(entity) {
    const result = await FqApi.put(`${baseUrl}/${entity.id}`, entity)
    return result.data
  },
  async deleteEntity(id) {
    const result = await FqApi.delete(`${baseUrl}/${id}`)
    return result.data
  }
}
